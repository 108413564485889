@font-face {
  font-family: Philosopher-Bold;
  src: url("../src/assets/fonts/Philosopher-Bold.ttf");
}
@font-face {
  font-family: Philosopher-Regular;
  src: url("../src/assets/fonts/Philosopher-Regular.ttf");
}
@font-face {
  font-family: Lato-Bold;
  src: url("../src/assets/fonts/Lato-Bold.ttf");
}
@font-face {
  font-family: Lato-Regular;
  src: url("../src/assets/fonts/Lato-Regular.ttf");
}
@font-face {
  font-family: Roboto-Bold;
  src: url("../src/assets/fonts/Roboto-Bold.ttf");
}
@font-face {
  font-family: Roboto-Regular;
  src: url("../src/assets/fonts/Roboto-Regular.ttf");
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, a Poppins_Black,
    Poppins_Medium, BebasNeue-Regular, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif, Inter_Bold,
    Inter_Regular;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
